import axios from "axios";

const instance = axios.create({
  // baseURL: "http://admin.zaya-ananda.com/api/",
   baseURL: "https://barilga.lvg.mn/api/v1/",
});

instance.defaults.withCredentials = true;

export default instance;
